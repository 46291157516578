



























































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Renwuxinxi from "@/components/biaozhu/renwuxinxi.vue";
import Jingdu from "@/components/biaozhu/jingdu.vue";
import {
  RenwuDetail,
  GetJindu,
  DeleteRenwu,
  GetBiaozhuyuan,
  Fenpei,
} from "@/request/mark";
@Component({
  components: {
    MainHeader,
    Renwuxinxi,
    Jingdu,
  },
})
export default class Name extends Vue {
  private id: any = "";
  private kind: any = "";
  private data: any = {};
  private ifShowJindu: any = false;
  private ifShowFenpei: any = false;
  private fenpeiText: any = "分配";
  private fenpeiData: any = {};
  private biaozhuyuan: any = [];
  private getData() {
    const params: any = {
      task_id: this.id,
      kind: this.kind,
    };
    RenwuDetail(this, params).then((res: any) => {
      this.data = res;
    });
  }
  private readJingdu() {
    this.ifShowJindu = true;
  }
  private delOne() {
    this.$confirm(
      "您确定要删除该标注任务么？删除后该标注任务相关的数据将全部清除。请慎重操作！",
      "删除",
      {
        customClass: "commonConfirm",
      }
    )
      .then(() => {
        const params: any = {
          data: {
            id: this.id,
          },
        };
        DeleteRenwu(this, params).then((res: any) => {
          this.$message.success("删除成功!");
          this.$router.back();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  private openfenpei(val: any) {
    this.fenpeiText = val;
    const params: any = {};
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
      this.ifShowFenpei = true;
    });
  }
  private remoteFenpeiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuan = res;
    });
  }
  private fenpeisure() {
    if (!this.fenpeiData) {
      this.$message.warning("请选择需要分配的数据标注员");
      return;
    }
    const params: any = {
      id: this.id,
      user_id: this.fenpeiData["标注员"],
    };
    Fenpei(this, params).then((res: any) => {
      this.$message.success(this.fenpeiText + "成功！");
      this.ifShowFenpei = false;
      this.getData();
    });
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    if (this.$route.query.kind) {
      this.kind = this.$route.query.kind;
    }
    this.getData();
  }
}
